html .dx-datagrid .dx-row>td,
html .dx-datagrid .dx-row>td a:link {
    margin: 0px 0px 0px 0px;
    padding: 7px 10px 5px 10px;
    font-size: 11px;
    line-height: 20px;
}

.dx-popup-wrapper>.dx-overlay-content {
    margin: 0px !important;
    padding: 0px !important;

    box-shadow: none !important;
    border-radius: 0 !important;
}



.dx-popup-content {
    padding: 0px !important;
}

.dx-popup-content .dx-dialog-content {
    padding: 10px !important;
}

#dx-confirm-content {
    width: 25vw;
    margin: 10px !important;
    /* background-color: red !important; */
}

.GridDetails .dx-scrollable-container {
    max-height: 200px;
}

.dx-datagrid-rowsview .dx-row-removed>td {
    background-color: rgb(163, 74, 74) !important;
}

/* html .dx-row-removed {
    background-color: red !important;
} */