#header {
    position: sticky;
    z-index: 2000;
    top: 0;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    height: 10vh;
}

#header-title {
    font-size: 26px;
}

li,
a {

    font-size: 16px;
    font-weight: bolder;
    line-height: 25px;
}