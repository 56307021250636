/* #entidade {
    width: 100%;
}
#tipo {
    width: 100%;
}
#emissao {
    width: 100%;  
}
#observacao {
    width: 100%;
} */

#teste {
    background-color: #a63843
}